const token =
  'eyJraWQiOiJTbHhaN2hxeThlRzciLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJTbHhaN2hxeThlRzdcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.lkb7nSTggG6g73U7RbYn_db6odxvy2R8clke7929NYCTVBX97MPv9Tsyk1xJOapVX5QsQ2C40RqPDS7MyF8JYA';

function selectAudioConfig(state) {
  const { isScrollEnabled, mediaId, playOutsideViewport } = state;
  const autoplayRequest = selectAutoplayRequest(state);
  return {
    token,
    src: `https://lw-amedia-cf.lwcdn.com/v-${mediaId}.mp4`,
    type: 'audio/mp4',
    float_on_scroll: isScrollEnabled ? true : false,
    autopause: isScrollEnabled ? false : !playOutsideViewport,
    ui: 'USE_THIN_CONTROLBAR',
    poster: null,
    autoplay: autoplayRequest,
  };
}

function selectVideoConfig(state) {
  const {
    isScrollEnabled,
    isMuted,
    playOutsideViewport,
    uiOverrides,
    startQuality,
    videoTitle,
    playerId,
    startTime,
    mediaId,
    logo,
    loop,
  } = state;
  const autoplayRequest = selectAutoplayRequest(state);
  return {
    token,
    autopause: isScrollEnabled ? false : !playOutsideViewport,
    preload: 'auto',
    float_on_scroll: isScrollEnabled ? true : false,
    muted: isMuted,
    skip_controls: true, // for playlist plugin
    src: `${playerId}/${mediaId}`,
    autoplay: autoplayRequest,
    lang: 'nb',
    ...(loop !== undefined && { loop }),
    ...(startTime !== undefined && { start_time: startTime }),
    ...(uiOverrides && { ui: uiOverrides }),
    ...(videoTitle && { title: videoTitle }),
    ...(startQuality && { start_quality: startQuality }),
    ...(![undefined, null].includes(logo) && {
      logo: logo.toString(),
      logo_alt_text: '',
    }),
  };
}

export function selectLogConfig(state) {
  const { player, mediaId, playerId } = state;
  const autoplayRequest = selectAutoplayRequest(state);
  return {
    autoplay: autoplayRequest > 0,
    title: player.opts.title,
    src: Array.isArray(player.opts.src) ? (player.opts.src[0]?.src ?? '') : '',
    contentId: `flowplayer:${mediaId}`,
    playerId,
    autopause: player.opts?.autopause,
    live: player.playerState.isLive ?? false,
    length: (player.opts?.duration ?? 0) * 1000,
    startTime: player.opts?.start_time ?? 0,
    productFeature: 'adplogger_sdk',
  };
}

export function selectAutoplayRequest(state) {
  const { prefersReducedMotion, isAudio, isAutoPlay, ovpConfig } = state;
  if (prefersReducedMotion && !isAudio) {
    return 0;
  }
  if (isAutoPlay !== undefined) {
    return isAutoPlay;
  }
  if (ovpConfig.autoplay !== undefined) {
    return ovpConfig.autoplay;
  }
  return 0;
}

export function selectShouldDispatchStateEvent(state) {
  const { playerState } = state;
  return (
    playerState.isLoaded &&
    !playerState.isStarting &&
    !playerState.isAdPlaying &&
    playerState.isInViewport
  );
}

export function selectShouldShowUnmute(state) {
  const autoplayRequest = selectAutoplayRequest(state);
  return (
    !state.isAudio &&
    autoplayRequest === 1 &&
    state.playerState?.isMuted &&
    !state.unmuteHidden
  );
}

export function selectPlayerConfig(state) {
  if (state.isAudio) {
    return selectAudioConfig(state);
  }

  return selectVideoConfig(state);
}

// on iOS - the ad is sharing the same video-container as the requested
// video. Thus we need to disregard the ended event.
export function isAdPlaying(state) {
  return state.playerState.isAdPlaying;
}

function leastCommonDenominator(width, height) {
  return height == 0 ? width : leastCommonDenominator(height, width % height);
}

function getAspectRatio(width, height) {
  const factor = leastCommonDenominator(width, height);
  const widthRatio = width / factor;
  const heightRatio = height / factor;
  return `${widthRatio}:${heightRatio}`;
}

export function selectVastParameters(state) {
  if (state.ovpConfig === undefined) {
    throw new Error('ovpConfig is undefined');
  }
  const [width, height] = (state.ovpConfig?.ratio?.split(':') || [1, 1]).map(
    Number
  );
  const { duration } = state.ovpConfig.metadata;
  return {
    autoplay: selectAutoplayRequest(state),
    page_url: state.pageUrl,
    media_duration: duration,
    video_url: state.ovpConfig.src.find((src) => src.includes('.m3u8')),
    media_id: state.ovpConfig.metadata.media_id,
    tags: (state.ovpConfig.metadata.tags ?? '').split(','),
    fritekst: (state.ovpConfig.metadata.ad_keywords ?? '').toLowerCase(),
    muted: state.isMuted,
    aspectratio: getAspectRatio(width, height),
    video_length:
      duration <= 45 ? 'short' : duration <= 120 ? 'medium' : 'long',
  };
}

/**
 * @typedef {import('@amedia/adplogger-sdk').AdpEvent.PlaybackEvent.payload} Payload
 * @param {{adpType: "playing" | "timeupdate" | "pause" | "ended", duration: number }}
 * @returns {Payload}
 */
export function adpLoggerEventDto({ adpType, duration }) {
  if (
    !['playing', 'timeupdate', 'pause', 'ended'].includes(adpType) ||
    Number.isNaN(duration) ||
    typeof duration !== 'number'
  ) {
    throw new Error(
      `Could not create payload duration "${duration}" for the event "${adpType}". Check the adpType and duration values`
    );
  }

  return {
    type: 'video',
    adpType,
    duration,
  };
}

/**
 * @typedef {import('@amedia/adplogger-sdk').AdpEvent.PlaybackAdEvent.payload} Payload
 * @param {{type: "ad-started" | "ad-paused" | "ad-resumed" | "ad-completed" | "ad-skipped", detail: {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number} | {vast_ad_id: string, ad_type: string} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number, adProgress: number, adDuration: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number, adProgress: number, adDuration: number, segmentDuration: number, adDuration: number, currentTime: number, adDuration: number, segmentDuration: number, adDuration: number}}
 * @returns {Payload}
 */
export function adpLoggerAdEventDto({
  adpType,
  adType,
  vastAdId,
  duration,
  adDuration,
}) {
  if (
    ![
      'ad-started',
      'ad-paused',
      'ad-resumed',
      'ad-completed',
      'ad-skipped',
    ].includes(adpType) ||
    Number.isNaN(duration) ||
    typeof duration !== 'number'
  ) {
    throw new Error(
      `Could not create payload duration "${duration}" for the event "${adpType}". Check the adpType and detail values!`
    );
  }

  return {
    type: 'video-ad',
    adType,
    adId: vastAdId,
    adpType,
    duration,
    length: adDuration,
  };
}

/**
 * Generates the inline styles for a video player based on the provided aspect ratio, video title, and teaser title flag.
 *
 * @param {Object} options - The options object.
 * @param {string} options.aspectRatio - The aspect ratio of the video player, type AspectRatio.
 * @param {string} options.videoTitle - The title of the video.
 * @param {boolean} options.hasTeaserTitle - Flag indicating if the video has a teaser title.
 * @return {string} The generated inline styles for the video player.
 */

export const getCustomCSSProperties = ({
  aspectRatio,
  videoTitle,
  hasTeaserTitle,
}) => {
  const titleLength = hasTeaserTitle && videoTitle ? videoTitle.length : 0;
  return `${aspectRatio?.toStyle() || ''} ${
    hasTeaserTitle ? `--brick-player-title-length: ${titleLength}` : ''
  }`.trim();
};
